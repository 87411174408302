<template>
  <div class="libContent">
    <div class="content_searchbox">
      <div class="content_title">按条件选器械</div>
      <div class="content_brand">
        <div class="content_subtitle">厂商</div>
        <div class="content_searchtitle box">
          <span class="content_searchtitle-title">热门厂商</span>
          <span
            class="content_searchtitle item"
            v-for="(item, index) in manufacturerList"
            :key="index"
            :class="{ checked: item.checked }"
            @click="handleSlected(item)"
            >{{ item.firmLetter }}</span
          >
        </div>


        <div class="content_searchbrand">
          <el-checkbox-group
            v-model="selectedList.apparatusFirmId"
            v-if="checkboxList.length != 0"
          >
            <el-checkbox
              v-for="(item, index) in checkboxList"
              :label="item.firmId"
              :key="index"
            >{{ item.firmName }}</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>



      <div class="content_type">
        <div class="content_subtitle">类型</div>
        <div class="type_cheked">
          <el-radio-group
            v-model="selectedList.typeList"
            @change="handleChange"
          >
            <el-radio
              v-for="(item, index) in checkTypeList"
              :key="index"
              :label="item.catId"
            >{{ item.catName }}</el-radio>
          </el-radio-group>
        </div>
        <div class="type_subChecked" v-if="checkTypesubList.length != 0">
          <el-checkbox-group v-model="selectedList.apparatusCatId">
            <el-checkbox
              v-for="(item, index) in checkTypesubList"
              :key="index"
              :label="item.catId"
            >{{ item.catName }}</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>




      <div class="content_selected">
        <div class="content_subtitle">已选</div>

        <div class="conetnt_tags">
          <span
            class="tags_contanier"
            v-for="item in allSelected"
            :key="item.id"
          >
            <el-tag color="#ffffff" closable @close="handleClose(item)">
              {{ item.name }}
            </el-tag>
          </span>
        </div>
        <div class="conetnt_clear">
          <span @click="clear">一键清空</span
          ><i @click="clear" class="iconfont icon-clear"></i>
        </div>
      </div>
      <div class="content_text">
        <div class="content_subtitle"></div>
        <div class="text_p">
          共<span class="text_span">{{ searchList.length }}</span
          >个产品符合条件
          <el-button
            type="primary"
            :class="{ down: isDown }"
            @click="jump"
            @mousedown.native="isDown = true"
            @mouseup.native="isDown = false"
            >点击查看</el-button
          >
        </div>
      </div>
    </div>
    <div class="content_center">
      <content-title :first="'热门器械'"></content-title>
      <release-layout
        :data="list"
        :row="4"
        :id="'layout1'"
        :type="'text'"
        :to="path"
      ></release-layout>
    </div>
    <div class="content_center" id="content_center">
      <content-title
        :first="'器械列表'"
        :count="searchList.length || '0'"
      ></content-title>
      <release-layout
        :data="searchList"
        :row="4"
        :id="'layout2'"
        :type="'text'"
        :to="path"
      ></release-layout>

      <div style="text-align: center;">
        <el-pagination
                background
                layout="prev, pager, next"
                :total="total"
                :page-size="10"
                :current-page="page"
                @current-change="currentChange">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import contentTitle from "../components/title.vue";
import releaseLayout from "../components/releaseLayout.vue";

import lib from "../api/lib";
import api from "../api/api";
export default {
  data() {
    return {
      manufacturerList: [], // 厂商列表字母查询
      checkboxList: [], // 厂商二级
      checkTypeList: [],// 一级类型
      checkTypesubList: [], // 二级类型
      selectedList: {
        apparatusFirmId: [],  // 选的厂商列表
        typeList: "", // 选的类型一级
        apparatusCatId: [], // 类型二级
      },
      list: [],
      searchList: [],
      path: {
        url: "/instrumentlib/info",
      },
      firmId: "",
      isDown: false,
      page:1,
      tagId:"",
      total: 0,
    };
  },
  components: { contentTitle, releaseLayout },
  // watch: {
  //   allSelected(val) {
  //     console.log(val)
  //     let data = {
  //       keyword: "",
  //     };
  //     for (let i = 0; i < val.length; i++) {
  //       data.keyword += val[i].name;
  //     }
  //     this.objData = data;
  //     this.searchApparatus(data)
  //   },
  // },
  computed: {
    allSelected: {
      get() {
        let arr = [];
        for (const i in this.selectedList) {
          if (!Array.isArray(this.selectedList[i])) {
            // if (this.selectedList[i] != "") {
            //   let data = {
            //     name: this.selectedList[i],
            //   };
            //   arr.push(data);
            // }
          } else {
            if (this.selectedList[i].length != 0) {

              if(i == 'apparatusFirmId') { // 厂商

                this.selectedList[i].map(v1 => {

                  this.manufacturerList.map(v2 => {
                    v2.data.map(v3 => {
                      if(v1 == v3.firmId) {
                        arr.push({
                          id: v3.firmId,
                          name: v3.firmName,
                        })
                      }
                    })
                  })

                })


              }

              if(i == 'apparatusCatId') { // 类型

                this.selectedList[i].map(v1 => {

                  this.checkTypeList.map(v2 => {
                    v2.childCate.map(v3 => {
                      if(v1 == v3.catId) {
                        arr.push({
                          id: v3.catId,
                          name: v3.catName,
                        })
                      }
                    })
                  })

                })
              }

            }
          }
        }

        return arr;
      },
      set(val) {},
    },
  },
  created() {
    this.getFirm();
    this.getCate();
    this.indexTopApparatus();
    this.searchApparatus();

  },
  methods: {
    clear() {
      this.selectedList.apparatusFirmId.splice(0, this.selectedList.apparatusFirmId.length);
      this.selectedList.typeList = "";
      this.selectedList.apparatusCatId.splice(
        0,
        this.selectedList.apparatusCatId.length
      );
    },
    handleClose(tag) {
      for (const i in this.selectedList) {
        let data = this.selectedList[i];
        if (Array.isArray(data)) {
          for (let c = 0; c < data.length; c++) {
            if (data[c] == tag.id) {
              data.splice(data.indexOf(data[c]), 1);
              break;
            }
          }
        } else {
          if (data == tag.id) {
            this.selectedList[i] = "";
            this.checkTypesubList = [];
          }
        }
      }
    },
    handleSlected(data) { // 厂商一级选择
      this.manufacturerList.forEach((item) => {
        item.checked = false;
        if (data.firmLetter == item.firmLetter) {
          this.checkboxList = item.data;
          this.firmId = item.firmId;
        }
      });
      data.checked = true;
      //this.manufacturerList.splice(this.manufacturerList.length);
    },
    handleChange(val) { // 类型一级选择
      this.checkTypesubList = [];
      this.checkTypeList.forEach((item) => {
        if (val == item.catId) {
          this.checkTypesubList = item.childCate;
        }
      });
    },
    currentChange(e) {
      this.page = e
      this.searchApparatus()
    },
    searchApparatus(){
      lib.searchApparatus({
        page: this.page,
        // ...this.selectedList,
        apparatusFirmId: this.selectedList.apparatusFirmId.join(','),
        apparatusCatId: this.selectedList.apparatusCatId.join(',')
      }).then((res) => {
        let newList = [];
        let list = res.data.data.data;

        list.forEach((item) => {
          let data = {
            id: item.apparatusId,
            catId: item.apparatusCatId,
            url: item.apparatusFileId,
            newsTitle: item.apparatusName,
            manufactor: item.firmName,
            // price:item.apparatusPrice,
          };
          newList.push(data);
        });
        this.searchList = newList;

        this.total = res.data.data.total;
      });
    },
    getFirm() {
      lib.getFirm().then((res) => {
        this.manufacturerList = res.data.data;
        this.manufacturerList.forEach((item) => {
          item.checked = false;
        });
      });
    },
    getCate() {
      lib.getCate(1).then((res) => {
        this.checkTypeList = res.data.data;
      });
    },
    indexTopApparatus() {
      let data = {
        type: "hots",
      };
      api.indexTopApparatus(data).then((res) => {
        let newList = [];
        let list = res.data.data.data;
        list.forEach((item) => {
          let data = {
            id: item.apparatusId,
            catId: item.apparatusCatId,
            url: item.apparatusFileId,
            newsTitle: item.apparatusName,
            manufactor: item.firmName,
            price: item.apparatusPrice,
          };
          newList.push(data);
        });
        this.list = newList;
      });
    },
    jump() {
      this.searchApparatus()
      document
        .querySelector("#content_center")
        .scrollIntoView({ behavior: "smooth" });
    },
  },

};
</script>
<style lang="scss">
.tags_contanier {
  .el-tag {
    border-radius: 21px;
    border-color: #f3f3f5;
    color: #111e36;
    .el-tag__close {
      color: #111e36;
      border: 1px solid #ffffff;
      &:hover {
        background: #ffffff;
        border: 1px solid #f3f3f5;
      }
    }
  }
}
.text_p {
  .el-button {
    margin-left: 27px;
    width: 113px;
    height: 47px;
    background: #0092ff;
    margin-right: 68px;
    font-size: 19px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    padding: 10px 20px;
    &.down {
      background: #fff;
      color: #0092ff;
    }
  }
}
.type_cheked {
  .el-radio {
    margin: 15px;
  }
}
</style> <style lang="scss" scoped>
.libContent {
  .content_searchbox {
    background: #ffffff;
    border: 1px solid #e9e9e9;
    .content_title {
      font-size: 21px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      padding: 13px;
      border-bottom: 1px solid #e9e9e9;
    }
    .content_brand {
      line-height: 70px;
      padding: 0 13px;
      display: grid;
      grid-template-columns: 100px auto;
      grid-template-rows: 70px auto;
      .content_subtitle {
        text-align: center;
        grid-row-start: span 2;
        font-size: 19px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }
      .content_searchtitle {
        .content_searchtitle-title {
          font-size: 19px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          margin-right: 57px;
        }
        &.box {
          display: flex;
          border-bottom: 1px solid #f0f0f0;
        }
        &.item {
          flex-grow: 1;
          text-align: center;
          font-size: 16px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          position: relative;
          cursor: pointer;
        }
        &.checked {
          &::after {
            content: "";
            display: block;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 12px solid #f0f0f0;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
    .content_type {
      line-height: 70px;
      padding: 0 13px;
      display: grid;
      grid-template-columns: 100px auto;
      grid-template-rows: 70px auto;
      .content_subtitle {
        text-align: center;
        grid-row-start: span 2;
        font-size: 19px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }
      .type_subChecked {
        padding: 27px;
        background: #f8f9fc;
      }
    }
    .content_selected {
      line-height: 70px;
      padding: 0 13px;
      display: grid;
      grid-template-columns: 100px auto;
      grid-template-rows: auto auto;
      position: relative;
      .content_subtitle {
        text-align: center;
        grid-row-start: span 2;
        font-size: 19px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }
      .conetnt_tags {
        padding-right: 65px;
        .tags_contanier {
          margin: 0 13px;
        }
      }
      .conetnt_clear {
        position: absolute;
        right: 13px;
        top: 0;
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #b5cbf8;
        cursor: pointer;
        user-select: none;
        span {
          margin-right: 12px;
        }
      }
    }
    .content_text {
      line-height: 70px;
      padding: 0 13px;
      display: grid;
      grid-template-columns: 100px auto;
      grid-template-rows: 100px auto;
      .content_subtitle {
        text-align: center;
        grid-row-start: span 2;
        font-size: 19px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }
      .text_p {
        text-align: right;
        font-size: 19px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        align-self: center;
        .text_span {
          color: #ff4747;
          margin: 0 5px;
        }
      }
    }
  }
  .content_center {
    margin-top: 40px;
    // background: #ffffff;
  }
}
</style>
